import {call, put} from "redux-saga/effects";
import api from "../../../services/api";

import {loadSuccess, loadFailure} from "./actions";
import {AxiosResponse} from "axios";

export function* load() {
  try {
    const response: AxiosResponse<any> = yield call(api.get, "users/lupuselit/repos");

    yield put(loadSuccess(response.data));
  } catch (err) {
    yield put(loadFailure());
  }
}
