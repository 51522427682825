import {put, call} from "redux-saga/effects";
import api from "../../../services/api";
import {setCurrentUser} from "./actions";
import {AxiosResponse} from "axios";

export function* getCurrentUser() {
  try {
    const token = JSON.parse(localStorage.getItem("_token") as any);
    const options = {
      headers: {Authorization: token || ""},
    };
    const user: AxiosResponse<any> = yield call(api.get, "/users", options);
    if (user !== undefined) {
      yield put(setCurrentUser(user.data));
    }
  } catch (error) {
    console.log(error);
  }
}
