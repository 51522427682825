import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/styles";

import store from "./store";
import "./sass/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "./theme/index2.scss";
import {createTheme} from "@mui/material";
import {Loading} from "./components/Loader";

// import { render } from 'react-snapshot';
//
// - ReactDOM.render(
// + render(
//     <App/>,
//     document.getElementById('root')
//   );
const LazyComponent = React.lazy(() => import("./Hindsyght"));
const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#154260",
    },
  },
  typography: {
    fontSize: 9, // Change the base font size here
  },
});

const root = ReactDOM.createRoot(document.getElementById("hindsyght") as HTMLElement);

setTimeout(() => {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={outerTheme}>
          <Suspense fallback={<Loading />}>
            <LazyComponent />
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}, 5);
